import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Tilt from 'react-tilt'
import Layout from '../components/layout'
import SEO from '../components/seo'
import pImg from '../images/1hero.jpg'
import Title from '../utils/Title'
import Services from '../utils/home/Services'
import Testimonials from '../utils/home/Testimonials'
import Portfolios from '../utils/Portfolios'
import Posts from '../utils/Posts'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Web and Software Developer"
      keywords={[
        'freelancer',
        'web developer',
        'app developer',
        'software engineer',
        'react developer',
        'react native developer',
        'javascript developer',
        'frontend developer',
        'headless cms',
        'headless wordpress specialist',
        'decoupled wordpress',
        'gatsby wordpress',
        'divi specialist',
        'plugin developer',
      ]}
      description="Zaman is a passionate web developer having 10+ years of experience in the web development field. Zaman specializes in wordpress, divi theme and plugins, headless cms, headless wordpress, gatsby with wordpress, PHP, javascript, react"
    />
    <section className="default-bg">
      <div className="container">
        <div className="row home-el">
          <div className="col s12 l5">
            <h1 className="home-title white-text">Meet Zaman</h1>
            <h5 className="white-text">Web and Software Developer</h5>
            <Link
              to="#thingsido"
              className="waves-effect waves-light btn-large home-btn"
            >
              <i className="material-icons right">arrow_downward</i>See what I'm
              into
            </Link>
          </div>
          <div className="col s12 l5 offset-l2">
            <Tilt className="Tilt" options={{ max: 25 }}>
              <Img
                className="home-image"
                fluid={data.zaman.childImageSharp.fluid}
              />
            </Tilt>
          </div>
        </div>
      </div>
      <div className="shape-divider" />
    </section>

    {/*Services*/}
    <section id="thingsido" className="section container services">
      <h2 className="center-align purple-text text-darken-3">Things I do</h2>
      <div className="row">
        <Services services={data.allWordpressWpServices.edges} />
      </div>
    </section>

    {/*Parallax*/}
    <div className="parallax-container">
      <div className="parallax">
        <img src={pImg} alt="parallax" />
      </div>
      <div className="container">
        <Title
          title="They Trusted Me!"
          styleClass="white-text default-title center-align"
        />
        <div className="center testimonials">
          <Testimonials testimonials={data.allWordpressWpTestimonials.edges} />
        </div>
      </div>
    </div>

    {/*Portfolio*/}
    <section className="blog container">
      <h2 className="center-align purple-text text-darken-3">Portfolios</h2>
      <div className="row">
        <Portfolios
          portfolios={data.allWordpressWpPortfolio.edges}
          parallax={data.parallax}
        />
      </div>
    </section>

    {/*Call to Action*/}
    <section className="contact">
      <div className="row container">
        <div className="col s12 m12 l10 call-to-action">
          <h3 className="white-text">Have a project in mind?</h3>
          <p className="white-text" style={{ fontSize: '18px' }}>
            Contact me to discuss the project and make it a reality!
          </p>
        </div>
        <div className="col s12 m12 l2 call-to-action">
          <Link
            to="/contact"
            className="waves-effect waves-light btn-large home-btn"
            style={{ marginTop: '45px' }}
          >
            <i className="material-icons right">arrow_forward</i>Contact Me
          </Link>
        </div>
      </div>
    </section>

    {/*Blog*/}
    <section className="blog container center">
      <h2 className="center-align purple-text text-darken-3">
        Freshly from the Blog
      </h2>
      <div className="row">
        <Posts posts={data.allWordpressPost.edges} parallax={data.parallax} />
      </div>
      <Link
        to="blog"
        className="waves-effect waves-light btn home-btn"
        style={{ margin: '25px 0' }}
      >
        <i className="material-icons right">library_books</i>All Posts
      </Link>
    </section>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: date, order: DESC }, limit: 4) {
      edges {
        node {
          date(formatString: "MMM DD, YYYY")
          title
          excerpt
          slug
          link
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpPortfolio(sort: { fields: date, order: ASC }, limit: 4) {
      edges {
        node {
          wordpress_id
          title
          link
          portfolio_category
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    parallax: file(relativePath: { eq: "1hero.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zaman: file(relativePath: { eq: "zamanq.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    allWordpressWpServices(sort: { fields: date, order: ASC }) {
      edges {
        node {
          title
          content
          acf {
            icon {
              localFile {
                childImageSharp {
                  fixed {
                    ...GatsbyImageSharpFixed_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpTestimonials {
      edges {
        node {
          title
          acf {
            role
            company_name
            testimonial_content
            image {
              localFile {
                childImageSharp {
                  fixed(width: 120, height: 120) {
                    ...GatsbyImageSharpFixed_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
