import React from 'react'

export default function Title({ title, styleClass }) {
  return <h2 className={styleClass}>{title}</h2>
}

Title.defaultProps = {
  title: 'This is a title',
  styleClass: 'default-title center-align purple-text text-darken-3',
}
