import React, { Component } from 'react'
import Img from 'gatsby-image'
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css'

class Testimonials extends Component {
  render() {
    const { testimonials } = this.props

    return (
      <Slider autoplay={2700}>
        {testimonials.map(({ node }) => (
          <div className="carousel-item">
            <p
              dangerouslySetInnerHTML={{
                __html: node.acf.testimonial_content,
              }}
            />
            {node.acf.image ? (
              <div className="testi-image">
                <Img
                  fixed={node.acf.image.localFile.childImageSharp.fixed}
                  alt={node.title}
                />
              </div>
            ) : null}
            <h3>
              {node.title} -{' '}
              <span>
                {node.acf.role}, <strong>{node.acf.company_name}</strong>
              </span>
            </h3>
          </div>
        ))}
      </Slider>
    )
  }
}

export default Testimonials
